import React, { useState } from 'react';
import { makeStyles, Snackbar, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';

const useStyles = makeStyles(theme => ({
    close: {
        padding: theme.spacing(0.5),
    },
}));

export const SnackbarAlert = ({
    shown,
    duration,
    message,
    status = '',
    anchor = { vertical: 'bottom', horizontal: 'center' },
    closable = false,
}) => {
    const classes = useStyles();
    const [snackbarShown, showSnackbar] = useState(shown);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        showSnackbar(false);
    };

    return (
        <Snackbar
            anchorOrigin={anchor}
            open={snackbarShown}
            autoHideDuration={duration}
            onClose={handleClose}
            ContentProps={{
                'aria-describedby': 'message-id',
            }}
            message={
                <span id="message-id">
                    {status === 'success' ? (
                        <CheckCircleIcon
                            className="mr-2"
                            style={{ marginTop: '-4px' }}
                        />
                    ) : null}
                    {status === 'error' ? (
                        <ErrorIcon
                            className="mr-2"
                            style={{ marginTop: '-4px' }}
                        />
                    ) : null}
                    {status === 'info' ? (
                        <InfoIcon
                            className="mr-2"
                            style={{ marginTop: '-4px' }}
                        />
                    ) : null}
                    {message}
                </span>
            }
            action={
                closable
                    ? [
                          <IconButton
                              key="close"
                              aria-label="Close"
                              color="inherit"
                              className={classes.close}
                              onClick={handleClose}
                          >
                              <CloseIcon />
                          </IconButton>,
                      ]
                    : []
            }
        />
    );
};
